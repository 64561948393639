<!--  -->
<template>
	<div :class="myslh=='elder'?'old':''">
		<swiper :options="swiperOption">
			　<swiper-slide v-for="item in imgs" :key="item.id">
				<img class="lb_img" :src="$url+'/'+item.img" alt="" />
				<p>
					<span class="c-3">{{ item.content }}</span>
				</p>
			</swiper-slide>
			<div class="swiper-pagination" slot="pagination"></div>
		</swiper>
		<ul class="c_content">
			<!-- <li>
        <a href="http://www.jj.gov.cn/col/col1229451969/index.html">
          <dd class="iconfont icon-zhengzhi"></dd>
          <p style="color: #6e6e6e">政务服务</p>
          <button>政务服务</button>
        </a>
      </li> -->

			<li>
				<dd class="iconfont icon-tuandui"></dd>
				<p>代办团队</p>
				<router-link to="/team" tag="button">代办团队</router-link>
			</li>
			<li>
				<dd class="iconfont icon-jindu"></dd>
				<p>代办进度</p>
				<router-link to="/plan" tag="button">代办进度</router-link>
			</li>
			<li>
				<dd class="iconfont icon-jieguo"></dd>
				<p>处理结果</p>
				<router-link to="/people" tag="button">处理结果</router-link>
			</li>
		</ul>
		<div class="submit1">
			<button type="primary" @click="reaction">我要反映</button>
		</div>
		<div style="height:5px"></div>
		<div class="submit1" v-if="myslh=='normal'">
			<button type="primary" @click="kaiqi('elder')">开启长辈版</button>
		</div>
		<div class="submit1" v-if="myslh=='elder'">
			<button type="primary" @click="kaiqi('normal')">关闭长辈版</button>
		</div>
		<div style="height: 55px"></div>
		<div class="txt-bottom">
			<!-- <p>浙江政务服务网</p> -->
			<p>本服务由浙江政务服务网、台州市椒江区信访局提供</p>
			<p>
				服务咨询热线：<span @click="dialPhoneNumber('0576-88832251')">0576-88832251</span>
			</p>
		</div>
		<!-- <div @click="asdasd">32132132</div> -->
	</div>

</template>

<script>
	import "../../assets/css/index/index.css";

	// vant-start
	import Vue from "vue";
	import { Button, Toast, Loading } from "vant";
	import * as zlb from "../../utils/zwjsbridge";
	Vue.use(Button);
	Vue.use(Loading);

	export default {
		name: "App",
		components: {
			zlb
		},
		data() {
			return {
				imgs: [],
				swiperOption: {
					pagination: {
						el: ".swiper-pagination",
						clickable: true, // 允许点击小圆点跳转
					},
					autoplay: {
						delay: 3000,
						disableOnInteraction: false, // 手动切换之后继续自动轮播
					},
					loop: true,
					navigation: {
						nextEl: ".swiper-button-next",
						prevEl: ".swiper-button-prev",
					},
					// on: {
					//   slideChangeTransitionEnd: function () {
					//     alert(this.activeIndex); //切换结束时，告诉我现在是第几个slide
					//   },
					// },
				},
				servicecode: "xfdbjfm",
				ticket: "",
				usertype: "",
				user: "",
				jwd: {},
				myslh: sessionStorage.getItem('getslh'),
				//  normal
			};
		},
		created() {

			console.log(this.imgs)
			this.imgs=JSON.parse(sessionStorage.getItem('imgs'))
			this.checkApp()
			// if (token != "") {
			// if (!sessionStorage.getItem('getslh')) {
			//   console.log('未读取到适老化信息，准备发出适老化请求');
			//   setTimeout(() => {
			//     console.log('触发延迟');
			//     this.getslh()
			//   }, 500)
			// }
			// this.imgList()  
			//   return
			// }

		},
		activated(){
			console.log(this.imgs)
			console.log("1激活activated钩子函数");
		},
		methods: {
			imgList() {
				let that = this;
				this.$get("/front/index/ad_list", {

				}).then(function (res) {
					that.imgs = res.list;
					sessionStorage.setItem("imgs",JSON.stringify(res.list))
				})
			},
			kaiqi(myslh_txt) {
				sessionStorage.setItem("getslh", myslh_txt)
				this.myslh = sessionStorage.getItem("getslh")
			},
			getslh() {
				// 检测适老化
				ZWJSBridge.onReady(() => {
					console.log('适老化准备完成');
					ZWJSBridge.getUiStyle({})
						.then((result) => {
							console.log('适老化检测请求成功');
							console.log(result.uiStyle);
							sessionStorage.setItem("getslh", result.uiStyle)
							this.myslh = sessionStorage.getItem("getslh")
							// location.reload()
						})
						//浙里办APP 6.11.0 版本以下版本标准模式兼容
						.catch((error) => { console.log(error); console.log('失败'); });
				})
			},
			isLoad() {
				var storage = window.localStorage;
				var token = storage.getItem("token") || "";
				let ticket = this.getQueryVariable("ticket");
				// alert(ticket)
				const sUserAgent = window.navigator.userAgent.toLowerCase();
				const bIsDtDreamApp = sUserAgent.indexOf("dtdreamweb") > -1; // 浙里办APP
				const bIsAlipayMini =
					sUserAgent.indexOf("miniprogram") > -1 &&
					sUserAgent.indexOf("alipay") > -1;
				// alert("bIsAlipayMini:"+bIsAlipayMini)
				// alert("bIsDtDreamApp:"+bIsDtDreamApp)
				console.log(token)
				if (token == "") {
					Toast.loading({
						duration: 0, // 持续展示 toast
						forbidClick: true,
						message: "登录中..."
					});
					if (!ticket) {
						if (bIsAlipayMini) {
							console.log("bIsAlipayMini:" + bIsAlipayMini)
							window.location.href =
								"https://puser.zjzwfw.gov.cn/sso/alipay.do?action=ssoLogin&servicecode=" + this.servicecode;
						} else if (bIsDtDreamApp) {
							console.log("bIsDtDreamApp:" + bIsDtDreamApp)
							window.location.href =
								"https://puser.zjzwfw.gov.cn/sso/mobile.do?action=oauth&scope=1&servicecode=" + this.servicecode;
						}
					} else {
						var data = {}
						data.ticket = ticket
						this.$post("/front/login", data)
							.then(res => {
								if (res.status == 1) {
									Toast.clear();
									this.user = res.user;
									var storage = window.localStorage;
									storage.setItem("token", res.token);
									storage.setItem("refresh_token", res.refresh_token);
									storage.setItem("exp", res.exp);
									storage.setItem("refresh_exp", res.refresh_exp);
									this.imgList()
									setTimeout(() => {
										this.getzlb();
										this.getdian();
										this.getslh()
									}, 0);
									//票据过期
								} else if (res.status == -1) {
									if (bIsAlipayMini) {
										window.location.href =
											"https://puser.zjzwfw.gov.cn/sso/alipay.do?action=ssoLogin&servicecode=" + this.servicecode;
									} else if (bIsDtDreamApp) {
										window.location.href =
											"https://puser.zjzwfw.gov.cn/sso/mobile.do?action=oauth&scope=1&servicecode=" + this.servicecode;
									}
								} else {
									Toast.fail(res.msg);
								}
							})
							.catch(function (err) {
								console.log(err);
								// alert("请求错误:" + err);
							});
					}
				} else {
					if (!sessionStorage.getItem('getslh')) {
						console.log('未读取到适老化信息，准备发出适老化请求');
						setTimeout(() => {
							console.log('触发延迟');
							this.getslh()
						}, 500)
					}
					console.log("777777777777"+this.imgs)
					this.imgList()
				}
			},
			checkApp() {
				const sUserAgent = window.navigator.userAgent.toLowerCase();
				const bIsDtDreamApp = sUserAgent.indexOf("dtdreamweb") > -1; // 浙里办APP
				const bIsAlipayMini =
					sUserAgent.indexOf("miniprogram") > -1 &&
					sUserAgent.indexOf("alipay") > -1;
				if (bIsDtDreamApp) {
					// alert("watchApp")
					console.log("watchApp")
					this.watchApp();
				} else if (bIsAlipayMini) {
					this.watchApply();
				}
			},
			watchApp() {
				var that = this;
				window.addEventListener(
					"pageshow",
					function (event) {
						// alert("close---------------------")
						if (
							event.persisted ||
							(window.performance && window.performance.navigation.type == 2)
						) {
							setTimeout(() => {
								ZWJSBridge.onReady(() => {
									ZWJSBridge.close();
								});
							}, 10);
						}
						console.log(that.imgs)
						console.log("pageshow8888888888888")
						that.isLoad()
					},
					false
				);
			},
			watchApply() {
				//@ts-ignore
				let that = this
				console.log("watchApply")
				window.addEventListener('pageshow', function (event) {
					if (event.persisted || (window.performance && (window.performance.navigation.type == 1 || window.performance.navigation.type == 0))) {
						that.isLoad()
					} else if (event.persisted || (window.performance && window.performance.navigation.type == 2)) {
						//@ts-ignore
						// ZWJSBridge.close()
						my.navigateBack();
					} else {
						my.navigateBack();
					}
				})
			},
			//埋点
			getdian() {
				(function (w, d, s, q, i) {
					w[q] = w[q] || [];
					var f = d.getElementsByTagName(s)[0],
						j = d.createElement(s);
					j.async = true;
					j.id = "beacon-aplus";
					j.src = "https://d.alicdn.com/alilog/mlog/aplus.js?id=202951085";
					f.parentNode.insertBefore(j, f);
				})(window, document, "script", "aplus_queue");

				//手动上报pv
				aplus_queue.push({
					action: "aplus.setMetaInfo",
					arguments: ["aplus-waiting", "MAN"],
				});
				aplus_queue.push({
					action: "aplus.setMetaInfo",
					arguments: ["aplus-rhost-v", "alog.zjzwfw.gov.cn"],
				});

				aplus_queue.push({
					action: "aplus.setMetaInfo",
					arguments: ["aplus-rhost-g", "alog.zjzwfw.gov.cn"],
				});

				// 这个会落到app_key字段上
				aplus_queue.push({
					action: "aplus.setMetaInfo",
					arguments: ["appId", "60506758"],
				});
				//应用开发管理平台miniAppId
				aplus_queue.push({
					action: "aplus.sendPV",
					arguments: [
						{
							is_auto: false,
						},
						{
							miniAppId: "2001816569",
						},
					],
				});
				//应用开发管理平台name
				aplus_queue.push({
					action: "aplus.sendPV",
					arguments: ["miniAppName", "信访代办解纷码"],
				});
				//  此时被block住的⽇志会携带上⽤户信息逐条发出
				aplus_queue.push({
					action: "aplus.setMetaInfo",
					arguments: ["_hold", "BLOCK"],
				});
				//经度
				aplus_queue.push({
					action: "aplus.sendPV",
					arguments: ["long", `${this.jwd.longitude}`],
				});
				//纬度
				aplus_queue.push({
					action: "aplus.sendPV",
					arguments: ["lati", `${this.jwd.latitude}`],
				});

				//用户类型
				aplus_queue.push({
					action: "aplus.sendPV",
					arguments: ["usertype", `${this.usertype}`],
				});

				// 设置⽤户昵称
				aplus_queue.push({
					action: "aplus.setMetaInfo",
					arguments: ["_user_nick", `${this.user.user_name}`],
				});
				// 设置⽤户ID
				aplus_queue.push({
					action: "aplus.setMetaInfo",
					arguments: ["_user_id", `${this.user.userid}`],
				});
				aplus_queue.push({
					action: "aplus.setMetaInfo",
					arguments: ["_hold", "START"],
				});
				console.log('getdian');
			},
			// 浙里办api获取判断
			getzlb() {
				console.log('getzlb');
				ZWJSBridge.onReady(() => {
					//获取用户类型
					ZWJSBridge.getUserType()
						.then((result) => {
							console.log(result);
							this.usertype = result.userType;
						})
						.catch((error) => {
							console.log(error);
						});
					//获取经纬度
					ZWJSBridge.getLocation().then((result) => {
						var jwd = result;
						this.jwd = jwd;
						var storage = window.localStorage;
						storage.setItem("city", jwd.city);
						storage.setItem("lat", jwd.latitude);
						storage.setItem("lon", jwd.longitude);
						console.log(storage.getItem("lat"));

						//如采集⽤户信息是异步⾏为，需要先设置完⽤户信息后再执⾏这个START埋点
						// 此时被block住的⽇志会携带上⽤户信息逐条发出

					});
				});
			},
			//获取url参数
			getQueryVariable(variable) {
				var query = window.location.search.substring(1);
				console.log("query---", query);
				var vars = query.split("&");
				for (var i = 0; i < vars.length; i++) {
					var pair = vars[i].split("=");
					if (pair[0] == variable) {
						return pair[1];
					}
					console.log(pair);
				}
				return false;
			},
			reaction() {
				this.$router.push("/reaction");
			},
			dialPhoneNumber(tel) {
				console.log(tel);
				ZWJSBridge.onReady(() => {
					ZWJSBridge.phoneCall({ corpId: tel })
						.then((result) => {
							console.log(result);
						})
						.catch((error) => {
							console.log(error);
						});
				}).catch(() => {
					console.log('onReadyErr');
				});
				// window.location.href = "tel:" + tel;
			},
		},
	};
</script>
<style scoped>
	.swiper-slide {
		position: relative;
		height: 200px;
	}

	.swiper-slide p {
		/* height: 50px; */
		width: 100%;
		position: absolute;
		bottom: 0;
		left: 0;
		padding: 10px 15px;
		box-sizing: border-box;
		color: white;
		background-color: rgba(0, 0, 0, 0.5);
	}

	.txt-bottom {
		text-align: center;
		/* position: fixed;
  bottom: 0;
  left: 0; */
		width: 100%;
		color: #9c9c9c;
		padding-bottom: 10px;
		padding-top: 10px;
		font-size: 12px;
		background: #fff;
	}

	.txt-bottom p {
		margin-bottom: 2px;
	}

	.txt-bottom p:last-child {
		margin-bottom: 0px;
	}

	.txt-bottom p span {
		color: #436eee;
	}

	.old p {
		font-size: 18px !important;
	}

	.old .txt-bottom p {
		padding: 0 15px;
	}

	.old .c_content li p {
		margin-top: 5px;
	}

	.c-3 {
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		word-break: break-all;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 3;
	}
</style>